<template>
  <div class="flp-btn btn-size2" style=" z-index:10;padding-left: 17px; margin: 0px 10px;">
    <transition name="expand">
      <div v-if="preparing" class="download-alert">
        <span v-if="preparing && !done" class="alt-txt1">Preparing download...</span>
        <span v-if="done" class="alt-txt2">Downloading Now</span>
        <div class="alt-loader">
          <div class="alt-loader-progress" :class="[done ? 'progress100' : '']"></div>
          <div class="alt-loader-bg"></div>
        </div>
      </div>
    </transition>
    Download
    <span class="flp-btn-icon-size2">
      <font-awesome-icon :icon="['fal', 'arrow-to-bottom']" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    preparing: {
      required: false,
      type: Boolean,
      default: false,
    },
    done: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // preparing: false,
      // done: false,
    };
  },
  methods: {
    // startDownload() {
    //   const vm = this;
    //   vm.preparing = true;
    //   vm.$emit('download-init');
    //   setTimeout(() => {
    //     vm.done = true;
    //     setTimeout(() => {
    //       vm.preparing = false;
    //       vm.done = false;
    //     }, 1000);
    //   }, 1000);
    // },
  },
};
</script>

<style lang="scss" scoped>
.ex-dl-btn {
  color: #303335;
}
.expand-enter-active,
.expand-leave-active {
  -webkit-transition: top 0.3s ease, opacity 0.3s ease;
  -moz-transition: top 0.3s ease, opacity 0.3s ease;
  -ms-transition: top 0.3s ease, opacity 0.3s ease;
  -o-transition: top 0.3s ease, opacity 0.3s ease;
  transition: top 0.3s ease, opacity 0.3s ease;
}

.expand-enter,
.expand-leave-to {
  top: 100% !important;
  opacity: 0 !important;
}

.progress100 {
  width: 100% !important;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-12 {
  width: 0.75em;
}

@media screen {
  * {
    box-sizing: border-box;
  }
  * {
    outline: 0;
  }
  .download-alert {
    position: absolute;
    top: 150%;
    left: 0;
    width: 250px;
    padding: 16px 30px;
    color: #fff;
    text-align: center;
    background-color: rgba(29, 33, 37, 0.95);
    border-radius: 5px;
    transform: translateX(-20%);
    // margin: 50px 0 0 -70px;
  }
  .flp-btn {
    position: relative;
    position: relative;
    display: inline-block;
    padding: 15px 26px;
    font-size: 12px;
    font-weight: 400;
    color: var(--overtext);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.07em;
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: var(--primarycolor) !important;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-radius: 3px;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -ms-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-touch-callout: none;
  }
  .flp-btn:hover {
    color: #fff !important;
    background-color: #424242 !important;
  }
  .btn-size2 {
    min-width: 20px;
    font-size: 12px;
    font-weight: 600;
    margin: 0px 7px 0px 10px;
    padding: 7px 12px 8px;
    border-radius: 6px;
    &:hover {
      color: #cad1d6 !important;
      background-color: #424242 !important;
      border: none !important;
    }
  }
  .flp-btn-icon-size2 {
    position: relative;
    top: 0;
    padding: 3px 4px 7px 12px;
    margin: 0 0 0 13px;
    font-size: 18px;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
  }
  .hide {
    display: none !important;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(111, 111, 111, 0.2);
    border: 0 #fff;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: 0 0;
    border: 0 #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: 0 0;
  }
  ::-webkit-scrollbar-track:active {
    background: 0 0;
  }
  ::-webkit-scrollbar-corner {
    background: 0 0;
  }
}

.fa-arrow-to-bottom:before {
  content: '\f33d';
}

.alt-loader {
  position: relative;
  display: block;
  width: 100%;
  height: 4px;
  margin: 11px 0px 2px 0px;
}
.alt-loader-bg {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.1);
}
.alt-loader-progress {
  position: absolute;
  z-index: 3;
  width: 1%;
  height: 4px;
  background-color: #00abb7;
  -webkit-transition: width 1s ease;
  -moz-transition: width 1s ease;
  -ms-transition: width 1s ease;
  -o-transition: width 1s ease;
  transition: width 1s ease;
}
</style>
